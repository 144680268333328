@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Averta-Reguler";
  src: url("./assets/fonts/Averta-Regular.otf");
}

@font-face {
  font-family: "Averta-Bold";
  src: url("./assets/fonts/averta-bold.otf");
}

@font-face {
  font-family: "Averta-Bold-Italic";
  src: url("./assets/fonts/averta-bold-italic.otf");
}

@font-face {
  font-family: "Averta-Black";
  src: url("./assets/fonts/averta-black.otf");
}

.marker-bg {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 65px;
  height: 75px;
  background-image: url('../public/location-pink.png') !important;
}

.marker-bg-red {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 65px;
  height: 75px;
  background-image: url('../public/location-red.png') !important;
}